import { forwardRef } from 'react'

//* Style
import BlogItemStyle from './style'

//* Components
import Image from 'components/common/Image'
import Text from 'components/common/Text'
import CustomLink from 'components/common/CustomLink'

//* Config
import { config } from 'helpers'

const BlogItem = forwardRef(({ blogData, className, verticalLineRef }, ref) => {
	return (
		<BlogItemStyle className={`${className || ''} col-4 col-t-6 col-ts-6 col-m-12`}>
			<CustomLink url={config.routes.blog.path + '/' + blogData.slug}>
				<div className='content-main-wrap' ref={ref}>
					<div className='blog-item-image-title-wrap'>
						<Image
							className={'blog-item-image'}
							src={blogData.featured_image?.url}
							alt={blogData.featured_image?.alt}
							objectFit={'cover'}
						/>
						<Text className={'uppercase p2 font-montserratarm-regular title'} text={blogData.title} />
					</div>
					<Text className={'p3 font-montserratarm-regular date'} text={blogData.date} />
				</div>
				{className.trim() !== 'last' && <div ref={verticalLineRef} className='line-vertical' />}
			</CustomLink>
		</BlogItemStyle>
	)
})

export default BlogItem
