import { memo as Memo, useRef } from 'react'
import { useRouter } from 'next/router'

//* Components
import Container from 'components/common/Container'
import Animate from 'components/common/Animate'
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import Values from 'components/pages/Global/Values'

//* Style
import SuccessStyle from './style'

const Success = Memo(({ title, content }) => {
	//! Router
	const router = useRouter()

	//! Ref
	const titleWrap = useRef()

	return (
		<SuccessStyle>
			<Animate
				startPoint={'+=20% bottom'}
				durationOpacity={0.4}
				startY={'50%'}
				params={{ opacityVertical: [titleWrap] }}
				dependency={router.asPath}
			>
				<Container row className={`globWrap`} ref={titleWrap}>
					<div className={`textWrap col-8 col-t-12`}>
						<Text tag={`h2`} className={`h5 primaryColor1 font-montserratarm-medium uppercase`} text={title} />
					</div>
					<div className={`btnWrap col-4 col-t-12`}>
						<Button text={'learnMore'} url={`/solution`} />
					</div>
				</Container>
			</Animate>

			<Values values={content} />
		</SuccessStyle>
	)
})

export default Success
