import { Fragment, useMemo, useRef, memo as Memo } from 'react'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import Text from 'components/common/Text'
import Container from 'components/common/Container'
import Image from 'components/common/Image'
import Animate from 'components/common/Animate'

//* Styles
import ValuesStyle from './style'

const Values = Memo(({ values, winWidth, screenSizes, title }) => {
	const valueTitle = useRef()
	const valueItemIcon = useRef([])
	const valueItemTitle = useRef([])
	const valueItemDescription = useRef([])
	const valueHorizontalLine1 = useRef()
	const valueHorizontalLine2 = useRef()
	const valueVerticalLines = useRef([])
	const valueHorizontalLines = useRef([])

	//! Store values
	const storeValues = useMemo(() => {
		return values.map((value, index) => {
			return (
				<Fragment key={index}>
					<Animate
						stagger={0.4}
						params={{
							lineVerticalPosVersion: winWidth < screenSizes.tabletSizeS ? [] : [valueVerticalLines],
							lineHorizontal: winWidth < screenSizes.tabletSizeS ? [valueHorizontalLines] : [],
						}}
					>
						<div className={`animWrap`}>
							<div className={'value-item'}>
								<Animate
									startY={'50%'}
									stagger={0.08}
									durationOpacity={0.4}
									params={{
										opacityVertical: [valueItemIcon, valueItemTitle, valueItemDescription],
									}}
								>
									<Image
										src={value.icon ? value.icon.url : '/images/default_icon.svg'}
										alt={value.icon ? value.icon.alt : 'Default icon'}
										objectFit={'cover'}
										className={'value-icon'}
										ref={(ref) => (valueItemIcon.current[index] = ref)}
									/>
									<Text
										className={'uppercase p1 font-montserratarm-medium value-title'}
										text={value.title}
										ref={(ref) => (valueItemTitle.current[index] = ref)}
										tag={`h3`}
									/>
									<Text
										className={'p font-montserratarm-light'}
										text={value.text}
										ref={(ref) => (valueItemDescription.current[index] = ref)}
									/>
								</Animate>
							</div>

							{values.length - 1 !== index &&
								(winWidth < screenSizes.tabletSizeS ? (
									<div ref={(ref) => (valueHorizontalLines.current[index] = ref)} className={`line`} />
								) : (
									<div ref={(ref) => (valueVerticalLines.current[index] = ref)} className={`verticalLine`} />
								))}
						</div>
					</Animate>
				</Fragment>
			)
		})
	}, [values, winWidth, screenSizes.tabletSizeS])

	return (
		<ValuesStyle>
			<Container section>
				{title && (
					<Animate
						startY={'50%'}
						durationOpacity={0.4}
						startPoint={'top bottom'}
						params={{
							opacityVertical: [valueTitle],
						}}
					>
						<Text
							tag={'h2'}
							className={'h5 uppercase primaryColor1 font-montserratarm-medium values-section-title'}
							text={title}
							ref={valueTitle}
						/>
					</Animate>
				)}

				<Animate
					stagger={0.3}
					params={{
						lineHorizontal: [valueHorizontalLine1, valueHorizontalLine2],
					}}
				>
					<div className='line' ref={valueHorizontalLine1} />
					<div className='values-wrap'>{storeValues}</div>
					<div className='line' ref={valueHorizontalLine2} />
				</Animate>
			</Container>
		</ValuesStyle>
	)
})

export default withUIContext(Values, ['winWidth', 'screenSizes'])
