import styled from 'styled-components'

const BlogListStyle = styled.div`
	display: flex;
	width: 100%;

	.row {
		padding: 0 calc(var(--sp6x) * -1);
		margin: 0 calc(var(--sp6x) * -1);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXLMin}) {
		.row {
			padding: 0 calc(var(--sp5x) * -1);
			margin: 0 calc(var(--sp5x) * -1);
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeLMin}) {
		.row {
			padding: 0 calc(var(--sp5x) * -1);
			margin: 0 calc(var(--sp5x) * -1);
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeMMin}) {
		.row {
			padding: 0 calc(var(--sp4x) * -1);
			margin: 0 calc(var(--sp4x) * -1);
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeSMin}) {
		.row {
			padding: 0 calc(var(--sp4x) * -1);
			margin: 0 calc(var(--sp4x) * -1);
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXSMin}) {
		.row {
			padding: 0 calc(var(--sp4x) * -1);
			margin: 0 calc(var(--sp4x) * -1);
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.TabletSizeMin}) {
		.row {
			padding: 0 calc(var(--sp3x) * -1);
			margin: 0 calc(var(--sp3x) * -1);
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		.row {
			padding: 0 calc(var(--sp3x) * -1);
			margin: 0 calc(var(--sp3x) * -1);
		}
	}
`

export default BlogListStyle
