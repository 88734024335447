import { memo as Memo, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'

//* Components
import Container from 'components/common/Container'
import Animate from 'components/common/Animate'
import Text from 'components/common/Text'
import Button from 'components/common/Button'

//* Style
import HomeServicesStyle from './style'

const HomeServices = Memo(({ title, services }) => {
	//! Router
	const router = useRouter()

	//! Refs
	const textRef = useRef()
	const btnRef = useRef()
	const itemsRef = useRef([])

	const servicesStore = useMemo(() => {
		return services.map((service, index) => (
			<Animate
				stagger={0.3}
				key={index}
				startY={'50%'}
				durationOpacity={0.5}
				startPoint={'+=20% bottom'}
				params={{
					opacityVertical: [itemsRef],
				}}
				dependency={router.asPath}
			>
				<div className={`col-6 col-ts-6 col-m-12 col-item`} ref={(ref) => (itemsRef.current[index] = ref)}>
					<Text tag={'p'} className={`h5 primaryColor3 font-montserratarm-medium`} text={`0${index + 1}`} />
					<Text tag={'h3'} className={`uppercase p1 primaryColor1 font-montserratarm-medium title`} text={service.title} />
					<Text tag={'p'} className={`p3 primaryColor1 font-montserratarm-regular text`} text={service.featured_text_home} />
				</div>
			</Animate>
		))
	}, [services, router.asPath, itemsRef.current])

	return (
		<HomeServicesStyle>
			<Container>
				<Container full row className={`titleWrap`}>
					<Animate durationOpacity={0.4} startY={'80%'} params={{ opacityVertical: [textRef] }} dependency={router.asPath}>
						<Text
							tag={'h2'}
							className={`uppercase h5 primaryColor1 font-montserratarm-medium col-8 col-t-9 col-ts-12 col-m-12`}
							text={title}
							ref={textRef}
						/>
					</Animate>

					<div className={`col-4 col-t-3`} />
				</Container>

				<Animate durationOpacity={0.4} startY={'80%'} params={{ opacityVertical: [btnRef] }} dependency={router.asPath}>
					<Button ref={btnRef} text={`allAreas`} url={'/expertise'} />
				</Animate>

				<Container full row className={`servicesWrap`}>
					<div className={`col-4`} />
					<div className={`col-8 col-ts-12 col-m-12`}>
						<Container full row>
							{servicesStore}
						</Container>
					</div>
				</Container>
			</Container>
		</HomeServicesStyle>
	)
})

export default HomeServices
