import styled from 'styled-components'

const SuccessStyle = styled.section`
	--globWrapMB: var(--sp10x);

	margin-bottom: var(--sectionsDistance);

	.globWrap {
		margin-bottom: var(--globWrapMB);

		.btnWrap {
			display: flex;
			align-items: center;
			justify-content: end;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeL}) {
		--globWrapMB: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--globWrapMB: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--globWrapMB: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--globWrapMB: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--globWrapMB: 0;

		.globWrap {
			.textWrap {
				.comp-title {
					width: 74.4%;
				}
			}

			.btnWrap {
				justify-content: start;
				margin-top: var(--sp4x);
				margin-bottom: var(--sp5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSizeS}) {
		--globWrapMB: 0;

		.globWrap {
			.btnWrap {
				justify-content: start;
				margin-top: var(--sp4x);
				margin-bottom: var(--sp5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--globWrapMB: 0;

		.globWrap {
			.btnWrap {
				justify-content: start;
				margin-top: var(--sp3x);
				margin-bottom: var(--sp5x);
			}
		}
	}
`

export default SuccessStyle
