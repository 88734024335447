import styled from 'styled-components'

const LowerBannerStyle = styled.div`
	--bannerPaddingTop: 31.3%;
	--contactBannerButtonMarginTop: var(--sp12x);

	.banner-wrap {
		position: relative;

		.contact-us-banner {
			width: 100%;
			padding-top: var(--bannerPaddingTop);
		}

		.layer {
			position: absolute;
			top: 0;
			background-color: var(--primaryColor2);
			width: 100%;
			height: 100%;
			opacity: 60%;
			z-index: 1;
		}

		.black-layer {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: var(--primaryColor2);
			z-index: 100;
		}

		.contact-us-banner-content {
			position: absolute;
			top: 50%;
			width: 100%;
			z-index: 2;
			transform: translateY(-50%);

			.contact-banner-button {
				margin-top: var(--contactBannerButtonMarginTop);
			}
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXLMin}) {
		--bannerPaddingTop: 33.4%;
		--contactBannerButtonMarginTop: var(--sp10x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeLMin}) {
		--bannerPaddingTop: 32.8%;
		--contactBannerButtonMarginTop: var(--sp7x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeMMin}) {
		--bannerPaddingTop: 33.7%;
		--contactBannerButtonMarginTop: var(--sp7x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeSMin}) {
		--bannerPaddingTop: 36.4%;
		--contactBannerButtonMarginTop: var(--sp6x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXSMin}) {
		--bannerPaddingTop: 39.3%;
		--contactBannerButtonMarginTop: var(--sp5x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.TabletSizeMin}) {
		--bannerPaddingTop: 55%;
		--contactBannerButtonMarginTop: var(--sp5x);
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--bannerPaddingTop: 100%;
		--contactBannerButtonMarginTop: var(--sp4x);

		.banner-wrap {
			.banner-description {
				margin-top: var(--sp3x);
			}
		}
	}
`

export default LowerBannerStyle
