import styled from 'styled-components'

const HeroStyle = styled.section.attrs((props) => ({}))`
	--fileWidth: 1576;
	--leftWrapWidth: 57.7%;
	--rightWrapWidth: 19.9%;
	--fileHeight: 56.6%;

	--globalWrapMarginSize: calc(-1 * var(--sp22x));

    --arrowWrapWidth: var(--sp6x);
    --arrowWrapBottom: var(--sp5x);
    --arrowWrapPrevRight: var(--sp15x);
    --arrowWrapNextRight: var(--sp5x);
    --sliderImage: var(--fileHeight);
    --iconSizes: var(--sp2x);

	margin-bottom: var(--sectionsDistance);

	.squareClipPathDefaultState {
		clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
	}
    
    .slider {
        position: relative;
        z-index: -1;
    }

    .slider-image {
        padding-top: var(--sliderImage);
    }

	.opacityZero {
		opacity: 0;
	}

    .arrow-wrap-next {
        position: absolute;
        right: var(--arrowWrapNextRight);
        bottom: var(--arrowWrapBottom);
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--arrowWrapWidth);
        height: var(--arrowWrapWidth);
        border: 2px solid var(--primaryColor1);
        border-radius: 50%;
        z-index: 1;
        transition: var(--trTime);
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                transform: scale(1.1);
            }
        }

        &.swiper-button-disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .slider-gallery-next {
            transform: rotate(45deg);
            font-size: var(--iconSizes);
        }
    }

    .arrow-wrap-prev {
        position: absolute;
        right: var(--arrowWrapPrevRight);
        bottom: var(--arrowWrapBottom);
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--arrowWrapWidth);
        height: var(--arrowWrapWidth);
        border: 2px solid var(--primaryColor1);
        border-radius: 50%;
        z-index: 1;
        transition: var(--trTime);
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                transform: scale(1.1);
            }
        }

        &.swiper-button-disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .slider-gallery-prev {
            transform: rotate(-135deg);
            font-size: var(--iconSizes);
        }
    }

	.globalWrapper {
		position: relative;
        background: linear-gradient(180deg,rgba(0,0,0,0.5) 0%,transparent 50%,rgba(0,0,0,0.5) 100%);

        //.imageCont,
		//.videoCont {
		//	transform: scale(1) translate3d(0, 0, 0);
		//	z-index: -1;
		//	padding-top: var(--fileHeight);
		//}

		.heroInfoWrap {
			position: absolute;
			bottom: 0;
			left: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
            margin-bottom: 3%;

			.leftWrap {
				width: var(--leftWrapWidth);
			}

			.rightWrap {
				width: var(--rightWrapWidth);
			}
		}

		margin-top: var(--globalWrapMarginSize);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeL}) {
		--fileWidth: 1184;
		--leftWrapWidth: 57.6%;
		--rightWrapWidth: 22.3%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp15x));

        --arrowWrapWidth: var(--sp5x);
        --arrowWrapBottom: var(--sp4x);
        --arrowWrapPrevRight: var(--sp12x);
        --arrowWrapNextRight: var(--sp4x);
        --iconSizes: var(--sp1-5x);
        
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--fileWidth: 928;
		--leftWrapWidth: 65.8%;
		--rightWrapWidth: 23.5%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp11x));

        --arrowWrapWidth: var(--sp5x);
        --arrowWrapBottom: var(--sp4x);
        --arrowWrapPrevRight: var(--sp12x);
        --arrowWrapNextRight: var(--sp4x);
        --iconSizes: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--fileWidth: 928;
		--leftWrapWidth: 57.5%;
		--rightWrapWidth: 23.6%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp11x));

        --arrowWrapWidth: var(--sp5x);
        --arrowWrapBottom: var(--sp4x);
        --arrowWrapPrevRight: var(--sp12x);
        --arrowWrapNextRight: var(--sp4x);
        --iconSizes: var(--sp1-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--fileWidth: 824;
		--leftWrapWidth: 57.4%;
		--rightWrapWidth: 23.5%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp9x));

        --arrowWrapWidth: var(--sp5x);
        --arrowWrapBottom: var(--sp4x);
        --arrowWrapPrevRight: var(--sp12x);
        --arrowWrapNextRight: var(--sp4x);
        --iconSizes: var(--sp1-5x);

		.addStyle {
			margin-top: var(--sp1x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--fileWidth: 656;
		--leftWrapWidth: 57.3%;
		--rightWrapWidth: 27%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp2x));

        --arrowWrapWidth: var(--sp4x);
        --arrowWrapBottom: var(--sp3x);
        --arrowWrapPrevRight: var(--sp10x);
        --arrowWrapNextRight: var(--sp3x);
        --iconSizes: var(--sp1x);

		.addStyle {
			margin-top: var(--sp1x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSizeS}) {
		--fileWidth: 488;
		--leftWrapWidth: 65.6%;
		--rightWrapWidth: 31.2%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: var(--sp2x);

        --arrowWrapWidth: var(--sp4x);
        --arrowWrapBottom: var(--sp3x);
        --arrowWrapPrevRight: var(--sp10x);
        --arrowWrapNextRight: var(--sp3x);
        --iconSizes: var(--sp1x);

		.addStyle {
			margin-top: var(--sp1x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--fileWidth: 392;
		--leftWrapWidth: 100%;
		--rightWrapWidth: 100%;
		--fileHeight: 56.6%;

        --arrowWrapWidth: var(--sp4x);
        --arrowWrapBottom: var(--sp2x);
        --arrowWrapPrevRight: var(--sp8x);
        --arrowWrapNextRight: var(--sp2x);
        --iconSizes: var(--sp1x);

		.globalWrapper {
			margin-top: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);

            //.imageCont,
			//.videoCont {
			//	position: absolute;
			//	top: 0;
			//	left: 0;
			//	transform: scale(1) translate3d(0, 0, 0) !important;
			//	margin-left: var(--sp1x);
			//	width: calc(100% - var(--sp2x));
			//}

			.heroInfoWrap {
				//position: relative;

				.leftWrap {
					margin-top: 50%;
				}

				.rightWrap {
					margin-top: var(--sp3x);

					.interWrap {
						width: 65.7%;
					}
				}
			}
		}

		.addStyle {
			margin-top: var(--sp1x);
		}
	}
`

export default HeroStyle
