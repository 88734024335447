import styled from 'styled-components'

const ValuesStyle = styled.div`
	--valueItemPadding: var(--sp13x);
	--valueIconSizes: var(--sp14x);
	--valueTitleMargin: var(--sp8x) 0 var(--sp5x) 0;
	--valuesSectionTitleMarginBottom: var(--sp10x);

	.verticalLine {
		position: absolute;
		background-color: var(--primaryColor1);
		width: 1px !important;
		right: 0;
		top: 0;
		opacity: 1 !important;
	}

	.values-wrap {
		display: flex;

		.animWrap {
			position: relative;
			flex: 1;
			padding: var(--valueItemPadding);

			.value-item {
				.value-icon {
					width: var(--valueIconSizes);
					padding-top: var(--valueIconSizes);
				}

				.value-title {
					margin: var(--valueTitleMargin);
				}
			}
		}
	}

	.values-section-title {
		margin-bottom: var(--valuesSectionTitleMarginBottom);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXLMin}) {
		--valueItemPadding: var(--sp10x);
		--valueIconSizes: var(--sp11x);
		--valueTitleMargin: var(--sp6x) 0 var(--sp4x) 0;
		--valuesSectionTitleMarginBottom: var(--sp8x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeLMin}) {
		--valueItemPadding: var(--sp7x);
		--valueIconSizes: var(--sp8x);
		--valueTitleMargin: var(--sp5x) 0 var(--sp3x) 0;
		--valuesSectionTitleMarginBottom: var(--sp5x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeMMin}) {
		--valueItemPadding: var(--sp6x);
		--valueIconSizes: var(--sp8x);
		--valueTitleMargin: var(--sp5x) 0 var(--sp3x) 0;
		--valuesSectionTitleMarginBottom: var(--sp5x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeSMin}) {
		--valueItemPadding: var(--sp6x);
		--valueIconSizes: var(--sp7x);
		--valueTitleMargin: var(--sp4x) 0 var(--sp3x) 0;
		--valuesSectionTitleMarginBottom: var(--sp5x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXSMin}) {
		--valueItemPadding: var(--sp4x);
		--valueIconSizes: var(--sp6x);
		--valueTitleMargin: var(--sp3x) 0 var(--sp2x) 0;
		--valuesSectionTitleMarginBottom: var(--sp4x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.TabletSizeMin}) {
		--valueItemPadding: var(--sp3x);
		--valueIconSizes: var(--sp5x);
		--valueTitleMargin: var(--sp3x) 0 var(--sp1-5x) 0;
		--valuesSectionTitleMarginBottom: var(--sp4x);

		.values-wrap {
			.animWrap {
				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--valueItemPadding: var(--sp3x) 0;
		--valueIconSizes: var(--sp5x);
		--valueTitleMargin: var(--sp3x) 0 var(--sp2x) 0;
		--valuesSectionTitleMarginBottom: var(--sp4x);

		.values-wrap {
			flex-direction: column;

			.animWrap {
				padding: 0;

				.value-item {
					padding: var(--valueItemPadding);
				}
			}
		}
	}
`

export default ValuesStyle
