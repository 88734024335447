import { memo as Memo, useRef } from 'react'

//* Components
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import Animate from 'components/common/Animate'

//* Styles
import LowerBannerStyle from './style'

const LowerBanner = Memo(({ file, title, text, link_text, buttonUrl }) => {
	//! Refs
	const bannerSectionTitle = useRef()
	const bannerSectionText = useRef()
	const bannerSectionButton = useRef()
	const bannerSectionBlackLayer = useRef()

	return (
		<LowerBannerStyle className={`lowerBannerWrap`}>
			<Container full className={'banner-wrap'}>
				<Image src={file?.url} alt={file?.alt} objectFit={'cover'} className={'contact-us-banner'} />

				<div className='contact-us-banner-content'>
					<Container row>
						<Animate
							startY={'100%'}
							durationOpacity={0.7}
							params={{
								opacityVertical: [bannerSectionTitle],
							}}
						>
							{title && (
								<Text
									className={'p1 uppercase font-montserratarm-medium col-4 col-m-12'}
									text={title || 'job'}
									ref={bannerSectionTitle}
								/>
							)}
						</Animate>
						<Animate
							startY={'100%'}
							durationOpacity={0.7}
							params={{
								opacityVertical: [bannerSectionText],
							}}
						>
							<Text className={'p2 font-montserratarm-regular col-8 banner-description col-m-12'} text={text} ref={bannerSectionText} />
						</Animate>
						<Animate
							startY={'100%'}
							durationOpacity={0.7}
							params={{
								opacityVertical: [bannerSectionButton],
							}}
						>
							<div ref={bannerSectionButton}>
								<Button className={'contact-banner-button'} size={'big'} text={link_text} url={buttonUrl} />
							</div>
						</Animate>
					</Container>
				</div>

				<div className='layer' />

				<Animate
					startPoint={'+=20% bottom'}
					params={{
						layerTopToBottom: [bannerSectionBlackLayer],
					}}
				>
					<div className='black-layer' ref={bannerSectionBlackLayer} />
				</Animate>
			</Container>
		</LowerBannerStyle>
	)
})

export default LowerBanner
