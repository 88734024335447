import { memo as Memo, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import Animate from 'components/common/Animate'
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import BlogList from 'components/pages/blog/blogList'

//* Style
import BlogHomeStyle from './style'

const BlogHome = Memo(({ title, posts, winWidth, screenSizes }) => {
	//! Router
	const router = useRouter()

	//! Ref
	const titleWrap = useRef()
	const firstLine = useRef()
	const secondLine = useRef()

	const postsItem = useMemo(() => {
		return winWidth < screenSizes.desktopSizeXS && winWidth >= screenSizes.tabletSizeS ? posts.slice(0, 2) : posts
	}, [winWidth, screenSizes, posts])

	return (
		<BlogHomeStyle>
			<Animate
				startPoint={'+=20% bottom'}
				durationOpacity={0.4}
				startY={'50%'}
				params={{ opacityVertical: [titleWrap] }}
				dependency={router.asPath}
			>
				<Container row className={`firstCont`} ref={titleWrap}>
					<div className={`textWrap col-8 col-t-12`}>
						<Text tag={`h2`} className={`h5 primaryColor1 font-montserratarm-medium uppercase`} text={title} />
					</div>
					<div className={`btnWrap col-4 col-t-12`}>
						<Button text={'allNews'} url={`/blog`} />
					</div>
				</Container>
			</Animate>

			<Container>
				<Animate stagger={0.5} params={{ lineHorizontal: [firstLine, secondLine] }}>
					<BlogList firstLine={firstLine} secondLine={secondLine} className={'first'} listData={postsItem} />
				</Animate>
			</Container>
		</BlogHomeStyle>
	)
})

export default withUIContext(BlogHome, ['winWidth', 'screenSizes'])
