import { memo as Memo, useMemo } from 'react'

//* HOC's
import withDataContext from 'context/consumerHOC/DataConsumer'

//* Components
import Page from 'components/common/Page'
import RelatedProjects from 'components/pages/Global/RelatedProjects'
import LowerBanner from 'components/pages/Global/LowerBanner'
import Hero from 'components/pages/home/HeroSection'
import HomeAboutUs from 'components/pages/home/AboutUsSection'
import HomeServices from 'components/pages/home/ServicesSection'
import Success from 'components/pages/home/SuccessSection'
import BlogHome from 'components/pages/home/BlogSection'

//* Style
import HomeStyle from 'styles/pages/HomeStyle'

const Home = Memo(({ global, pages, pageParams, selectedLang }) => {
	//! Global Data
	const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

	//! Page Data
	const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name])

	return (
		<Page className={`home`} meta={pageData?.content?.meta}>
			{pageData && (
				<HomeStyle>
					<Hero file={pageData.content.hero.file} text={pageData.content.hero.text} />

					<HomeAboutUs
						title={pageData.content.about_us.title}
						text={pageData.content.about_us.text}
						fact_1={pageData.content.about_us.fact_1}
						fact_2={pageData.content.about_us.fact_2}
						fact_3={pageData.content.about_us.fact_3}
					/>

					{pageData.content.services.services.length > 0 && (
						<HomeServices title={pageData.content.services.title} services={pageData.content.services.services} />
					)}

					<RelatedProjects
						sectionTitle={pageData.content.projects.title}
						buttonName={'allProjects'}
						slug={'/projects/'}
						projects={pageData.content.projects.projects}
						slider={true}
					/>

					<Success title={pageData.content.success.title} content={pageData.content.success.facts} />

					{globalData && (
						<LowerBanner
							file={globalData.contact_us_call_to_action.file}
							text={globalData.contact_us_call_to_action.text}
							link_text={globalData.contact_us_call_to_action.link_text}
							buttonUrl={`/contact-us`}
							page={'home'}
						/>
					)}

					{pageData.content.blog.posts.length > 0 && <BlogHome title={pageData.content.blog.title} posts={pageData.content.blog.posts} />}
				</HomeStyle>
			)}
		</Page>
	)
})

export default withDataContext(Home, ['pages', 'global'])
