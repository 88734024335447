import styled from 'styled-components'

const VideoStyle = styled.div`
	--videoPadTop: 50.5%;

	&.videoCont {
		position: relative;
		width: 100%;
		padding-top: var(--videoPadTop);

		video {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
`
export default VideoStyle
