import styled from 'styled-components'

const BlogItemStyle = styled.div`
	--colPadding: var(--sp6x);
	--titleMargin: var(--sp6x) 0 var(--sp5x) 0;

	position: relative;
	padding: var(--colPadding) !important;

	.line-vertical {
		position: absolute;
		top: 0;
		right: 0;
	}

	.blog-item-image {
		width: 100%;
		padding-top: 65.8%;
	}

	.content-main-wrap {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.blog-item-image-title-wrap {
			.title {
				margin: var(--titleMargin) !important;
			}
		}
	}

	.date {
		display: flex;
		justify-content: flex-end;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXLMin}) {
		--colPadding: var(--sp5x);
		--titleMargin: var(--sp5x) 0 var(--sp4x) 0;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeLMin}) {
		--colPadding: var(--sp5x);
		--titleMargin: var(--sp5x) 0 var(--sp4x) 0;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeMMin}) {
		--colPadding: var(--sp4x);
		--titleMargin: var(--sp4x) 0 var(--sp3x) 0;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeSMin}) {
		--colPadding: var(--sp4x);
		--titleMargin: var(--sp4x) 0 var(--sp3x) 0;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXSMin}) {
		--colPadding: var(--sp4x);
		--titleMargin: var(--sp4x) 0 var(--sp3x) 0;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.TabletSizeMin}) {
		--colPadding: var(--sp3x);
		--titleMargin: var(--sp3x) 0 var(--sp2-5x) 0;
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--colPadding: var(--sp3x);
		--titleMargin: var(--sp3x) 0 var(--sp2x) 0;
	}
`

export default BlogItemStyle
