import { memo as Memo, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import Container from 'components/common/Container'
import Animate from 'components/common/Animate'
import Text from 'components/common/Text'
import Button from 'components/common/Button'

//* Style
import HomeAboutUsStyle from './style'

const HomeAboutUs = Memo(({ title, text, fact_1, fact_2, fact_3, winWidth, screenSizes }) => {
	//! Router
	const router = useRouter()

	//! Refs
	const fact1NumberRef = useRef()
	const fact2NumberRef = useRef()
	const fact3NumberRef = useRef()

	const titleRef = useRef()
	const textRef = useRef()
	const btnRef = useRef()
	const line1Ref = useRef()
	const line2Ref = useRef()
	const line3Ref = useRef()
	const line4Ref = useRef()
	const line5Ref = useRef()
	const fact1TextRef = useRef()
	const fact2TextRef = useRef()
	const fact3TextRef = useRef()

	//! Facts store
	const factsStore = useMemo(() => {
		return (
			<Container row full className={`bannerIntWrap`}>
				<div className={`emptyWrap col-dm-4 col-ts-3`} />

				<div className={`col-12 col-dm-8 col-ts-9 col-m-12`}>
					<div className={`factsGlobWrapper`}>
						<div className={`primaryWrap`}>
							<div className={`fact1Wrap`}>
								<div className={`horizontalLine top`} ref={line1Ref} />

								<div className={`additionalWrap maxWidthStyle`}>
									<Animate
										startPoint={'+=20% bottom'}
										durationOpacity={0.4}
										startY={'30%'}
										params={{ numberAnime: [fact1NumberRef], opacityVertical: [fact1TextRef] }}
										dependency={router.asPath}
										textContent={fact_1.number}
									>
										<div className={`numberWrap`}>
											<Text
												tag={`p`}
												className={`h3 font-montserratarm-medium primaryColor3 mobileStyle`}
												text={`0`}
												ref={fact1NumberRef}
											/>
										</div>

										<Text
											tag={`p`}
											className={`uppercase p1 font-montserratarm-medium primaryColor1 factText`}
											text={fact_1.text}
											ref={fact1TextRef}
										/>
									</Animate>
								</div>

								<div className={`horizontalLine bottom`} ref={line2Ref} />
							</div>

							<div className={`fact2Wrap`}>
								<Animate
									startPoint={'+=20% bottom'}
									durationOpacity={0.4}
									startY={'30%'}
									params={{ numberAnime: [fact2NumberRef], opacityVertical: [fact2TextRef] }}
									dependency={router.asPath}
									textContent={fact_2.number}
								>
									<Text
										tag={`p`}
										className={`h3 font-montserratarm-medium primaryColor3 mobileStyle`}
										text={`0`}
										ref={fact2NumberRef}
									/>

									<Text
										tag={`p`}
										className={`uppercase p1 font-montserratarm-medium primaryColor1 factText`}
										text={fact_2.text}
										ref={fact2TextRef}
									/>
								</Animate>

								<div className={`horizontalLine bottom-reverse`} ref={line3Ref} />
							</div>
						</div>

						<div className={`secondaryWrap`}>
							<div className={`fact3Wrap`}>
								<Animate
									startPoint={'+=20% bottom'}
									durationOpacity={0.4}
									startY={'30%'}
									params={{ numberAnime: [fact3NumberRef], opacityVertical: [fact3TextRef] }}
									dependency={router.asPath}
									textContent={fact_3.number}
								>
									<div className={`flexWrap`}>
										<Text
											tag={`p`}
											className={`h3 font-montserratarm-medium primaryColor3 mobileStyle`}
											text={`0`}
											ref={fact3NumberRef}
										/>

										<Text tag={`p`} className={`h3 font-montserratarm-medium primaryColor3 mobileStyle`} text={`+`} />
									</div>

									<Text
										tag={`p`}
										className={`uppercase p1 font-montserratarm-medium primaryColor1 factText`}
										text={fact_3.text}
										ref={fact3TextRef}
									/>
								</Animate>

								<div className={`horizontalLine bottom-reverse`} ref={line4Ref} />
							</div>
						</div>

						<div className={`verticalLine`} ref={line5Ref} />
					</div>
				</div>
			</Container>
		)
	}, [fact_1, fact_2, fact_3])

	return (
		<HomeAboutUsStyle>
			<Container row className={`firstColWrap`}>
				<Animate
					delay={winWidth < screenSizes.tabletSizeS && 0.8}
					durationOpacity={0.4}
					startY={'80%'}
					params={{ opacityVertical: [titleRef] }}
					dependency={router.asPath}
				>
					<div className={`titleWrap col-8 col-t-12 opacityZero`} ref={titleRef}>
						<Text tag={`h2`} className={`h5 primaryColor1 font-montserratarm-medium uppercase`} text={title} />
					</div>
				</Animate>

				<div className={`col-4 col-t-12`} />
			</Container>

			<Container row className={`secondColWrap`}>
				<div className={`infoWrap col-4 col-dm-12`}>
					<Animate
						delay={winWidth < screenSizes.tabletSizeS && 0.85}
						durationOpacity={0.4}
						startY={'30%'}
						params={{ opacityVertical: [textRef] }}
						dependency={router.asPath}
					>
						<Text tag={`p`} className={`p3 primaryColor1 font-montserratarm-regular text opacityZero`} text={text} ref={textRef} />
					</Animate>

					<Animate
						delay={winWidth < screenSizes.tabletSizeS && 0.9}
						durationOpacity={0.4}
						startY={'80%'}
						params={{ opacityVertical: [btnRef] }}
						dependency={router.asPath}
					>
						<div className={`btnWrap opacityZero`} ref={btnRef}>
							<Button text={'moreAboutUs'} url={`/about-us`} />
						</div>
					</Animate>
				</div>

				<Animate
					startPoint={'+=20% bottom'}
					durationHorizontal={0.2}
					params={{
						lineHorizontal: [line1Ref],
					}}
				>
					<Animate
						stagger={0.1}
						delay={0.2}
						durationHorizontal={0.3}
						durationVertical={0.2}
						params={{
							lineHorizontal: [line2Ref, line3Ref, line4Ref],
							lineVertical: [line5Ref],
						}}
					>
						<div className={`bannerWrap col-8 col-dm-12`}>{factsStore}</div>
					</Animate>
				</Animate>
			</Container>
		</HomeAboutUsStyle>
	)
})

export default withUIContext(HomeAboutUs, ['winWidth', 'screenSizes'])
