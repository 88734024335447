import styled from 'styled-components'

const HomeAboutUsStyle = styled.section`
	--titleWrapMB: var(--sp5x);
	--textWidth: 92.9%;
	--btnWrapMT: var(--sp10x);
	--leftWrapWidth: 57.9%;
	--rightWrapWidth: calc(100% - var(--leftWrapWidth));
	--paddingSize: var(--sp13x);
	--marginSize: var(--sp3x);
	--factTextWidth: 90%;
	--maxWidth: 79%;

	margin-bottom: var(--sectionsDistance);

	.maxWidthStyle {
		width: 100%;
		max-width: var(--maxWidth);
	}

	.flexWrap {
		display: flex;
	}

	.opacityZero {
		opacity: 0;
	}

	.firstColWrap {
		margin-bottom: var(--titleWrapMB);
	}

	.secondColWrap {
		.infoWrap {
			.text {
				width: var(--textWidth);
			}

			.btnWrap {
				margin-top: var(--btnWrapMT);
			}
		}

		.bannerWrap {
			.bannerIntWrap {
				.factsGlobWrapper {
					position: relative;

					.primaryWrap {
						display: flex;

						.fact1Wrap {
							position: relative;
							display: flex;
							justify-content: flex-end;
							width: var(--leftWrapWidth);
							padding: var(--paddingSize);
							padding-left: 0;

							.numberWrap {
								display: flex;
							}
						}

						.fact2Wrap {
							position: relative;
							width: var(--rightWrapWidth);
							padding: var(--paddingSize);
							padding-right: 0;
						}
					}

					.secondaryWrap {
						display: flex;
						justify-content: flex-end;

						.fact3Wrap {
							position: relative;
							width: var(--rightWrapWidth);
							padding: var(--paddingSize);
							padding-right: 0;
						}
					}

					.factText {
						max-width: var(--factTextWidth);
						margin-top: var(--marginSize);
					}

					.horizontalLine {
						position: absolute;
						background-color: var(--primaryColor1);
						width: 100%;
						height: 1px;

						&.top {
							left: 0;
							top: 0;
						}

						&.bottom {
							bottom: 0;
							right: 0;
						}

						&.bottom-reverse {
							bottom: 0;
							left: 0;
						}
					}

					.verticalLine {
						position: absolute;
						background-color: var(--primaryColor1);
						width: 1px;
						left: var(--leftWrapWidth);
						top: 0;
						bottom: 0;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeL}) {
		--titleWrapMB: var(--sp4x);
		--textWidth: 94.3%;
		--btnWrapMT: var(--sp8x);
		--paddingSize: var(--sp9x);
		--marginSize: var(--sp2x);
		--leftWrapWidth: 58%;
		--factTextWidth: 96%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--titleWrapMB: var(--sp3x);
		--textWidth: 65.8%;
		--btnWrapMT: var(--sp5x);
		--paddingSize: var(--sp7x);
		--marginSize: var(--sp2x);
		--leftWrapWidth: 56.9%;
		--factTextWidth: 78%;

		.secondColWrap {
			.bannerWrap {
				margin-top: var(--sp7x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--titleWrapMB: var(--sp3x);
		--textWidth: 65.9%;
		--btnWrapMT: var(--sp5x);
		--paddingSize: var(--sp6x);
		--marginSize: var(--sp2x);
		--leftWrapWidth: 53.6%;
		--factTextWidth: 83%;
		--maxWidth: 77%;

		.secondColWrap {
			.bannerWrap {
				margin-top: var(--sp7x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--titleWrapMB: var(--sp3x);
		--textWidth: 66%;
		--btnWrapMT: var(--sp5x);
		--paddingSize: var(--sp6x);
		--marginSize: var(--sp1x);
		--leftWrapWidth: 54.9%;
		--maxWidth: 85%;
		--factTextWidth: 93%;
		--maxWidth: 77%;

		@media only screen and (min-width: 1366px) {
			--maxWidth: 71%;
		}

		.secondColWrap {
			.bannerWrap {
				margin-top: var(--sp6x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--titleWrapMB: var(--sp3x);
		--textWidth: 65.9%;
		--btnWrapMT: var(--sp4x);
		--paddingSize: var(--sp4x);
		--marginSize: var(--sp1x);
		--leftWrapWidth: 55.7%;
		--maxWidth: 91%;
		--factTextWidth: 90%;
		--maxWidth: 81%;

		.firstColWrap {
			.titleWrap {
				.comp-title {
					width: 74.4%;
				}
			}
		}

		.secondColWrap {
			.bannerWrap {
				margin-top: var(--sp5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSizeS}) {
		--titleWrapMB: var(--sp3x);
		--textWidth: 65.6%;
		--btnWrapMT: var(--sp4x);
		--paddingSize: var(--sp4x);
		--marginSize: var(--sp1x);
		--leftWrapWidth: 60.6%;
		--maxWidth: 86%;
		--factTextWidth: 95%;
		--maxWidth: 76%;

		.secondColWrap {
			.bannerWrap {
				margin-top: var(--sp5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--titleWrapMB: var(--sp2x);
		--textWidth: 100%;
		--btnWrapMT: var(--sp4x);
		--paddingSize: var(--sp3x);
		--marginSize: var(--sp1x);
		--leftWrapWidth: 55.4%;
		--factTextWidth: 100%;
		--maxWidth: 94%;

		.secondColWrap {
			.bannerWrap {
				margin-top: var(--sp5x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.MobileSizeSMin}) {
			--maxWidth: 100%;
		}
	}
`

export default HomeAboutUsStyle
