import styled from 'styled-components'

const HomeServicesStyle = styled.section`
	--titleWrapMB: var(--sp10x);
	--servicesWrapMT: var(--sp12x);
	--largeSize: var(--sp4x);
	--smallSize: var(--sp3x);
	--distance: var(--sp4x);

	margin-bottom: var(--sectionsDistance);

	.titleWrap {
		margin-bottom: var(--titleWrapMB);
	}

	.servicesWrap {
		margin-top: var(--servicesWrapMT);

		.title {
			margin-top: var(--largeSize);
		}

		.text {
			margin-top: var(--smallSize);
		}

		.col-item {
			margin-top: var(--distance);

			&:nth-child(1),
			&:nth-child(2) {
				margin-top: 0;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeL}) {
		--titleWrapMB: var(--sp8x);
		--servicesWrapMT: var(--sp10x);
		--largeSize: var(--sp4x);
		--smallSize: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--titleWrapMB: var(--sp5x);
		--servicesWrapMT: var(--sp7x);
		--largeSize: var(--sp3x);
		--smallSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--titleWrapMB: var(--sp5x);
		--servicesWrapMT: var(--sp7x);
		--largeSize: var(--sp3x);
		--smallSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--titleWrapMB: var(--sp5x);
		--servicesWrapMT: var(--sp6x);
		--largeSize: var(--sp3x);
		--smallSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--titleWrapMB: var(--sp4x);
		--servicesWrapMT: var(--sp5x);
		--largeSize: var(--sp2x);
		--smallSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSizeS}) {
		--titleWrapMB: var(--sp4x);
		--servicesWrapMT: var(--sp5x);
		--largeSize: var(--sp2x);
		--smallSize: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--titleWrapMB: var(--sp3x);
		--servicesWrapMT: var(--sp5x);
		--largeSize: var(--sp2x);
		--smallSize: var(--sp2x);

		.servicesWrap {
			.col-item {
				&:nth-child(2) {
					margin-top: var(--sp4x);
				}
			}
		}
	}
`

export default HomeServicesStyle
