import { forwardRef, useEffect, useState } from 'react'

//* Style
import VideoStyle from './style'

const CustomVideo = forwardRef((props, ref) => {
	const [check, setCheck] = useState(true)

	useEffect(() => {
		if (!props.autoPlay) {
			setCheck(props.autoPlay)
		}
	}, [props.autoPlay])

	return (
		<VideoStyle className={`videoCont ${props.className || ''}`} ref={ref}>
			<video
				onEnded={() => props.setPlay(true)}
				autoPlay={check}
				muted={check}
				loop={props.loop}
				controls={props.controls}
				playsInline
				onClick={props.handleClick}
			>
				<source src={props.src} type='video/mp4' />
			</video>
		</VideoStyle>
	)
})

CustomVideo.displayName = 'CustomVideo'

export default CustomVideo
