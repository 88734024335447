import styled from 'styled-components'

const BlogHomeStyle = styled.section`
	--firstContMTop: var(--sp10x);

	margin-bottom: var(--sectionsDistance);

	.btnWrap {
		display: flex;
		align-items: center;
		justify-content: end;
	}

	.firstCont {
		margin-bottom: var(--firstContMTop);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeL}) {
		--firstContMTop: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--firstContMTop: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--firstContMTop: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		--firstContMTop: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--firstContMTop: 0;

		.textWrap {
			.comp-title {
				width: 74.4%;
			}
		}

		.btnWrap {
			justify-content: start;
			margin-top: var(--sp4x);
			margin-bottom: var(--sp5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSizeS}) {
		--firstContMTop: 0;

		.btnWrap {
			justify-content: start;
			margin-top: var(--sp4x);
			margin-bottom: var(--sp5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--firstContMTop: 0;

		.btnWrap {
			justify-content: start;
			margin-top: var(--sp3x);
			margin-bottom: var(--sp5x);
		}
	}
`

export default BlogHomeStyle
