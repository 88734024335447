import { useMemo, useRef } from 'react'

//* Components
import BlogItem from 'components/pages/blog/blogItem'
import Container from 'components/common/Container'
import Animate from 'components/common/Animate'

//* Style
import BlogListStyle from './style'

const BlogList = ({ listData, className, firstLine, secondLine }) => {
	//! Refs
	const verticalLineRef = useRef([])
	const blogItems = useRef([])

	//! Store list
	const storeListItems = useMemo(() => {
		return listData.map((item, index) => (
			<BlogItem
				ref={(ref) => (blogItems.current[index] = ref)}
				verticalLineRef={(ref) => (verticalLineRef.current[index] = ref)}
				className={`${listData.length - 1 === index ? 'last' : ''} ${index === 0 ? 'first' : ''}`}
				blogData={item}
				key={index}
			/>
		))
	}, [listData])

	return (
		<>
			{className.trim() === 'first' && <div ref={firstLine} className='line' />}
			<BlogListStyle>
				<Container full row>
					<Animate stagger={0.4} startY={'20%'} params={{ lineVertical: [verticalLineRef] }}>
						<Animate
							stagger={0.08}
							startY={'20%'}
							durationOpacity={0.7}
							params={{ lineVertical: [verticalLineRef], opacityVertical: [blogItems] }}
						>
							{storeListItems}
						</Animate>
					</Animate>
				</Container>
			</BlogListStyle>
			<div className='line' ref={secondLine} />
		</>
	)
}

export default BlogList
